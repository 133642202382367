export const environment = {
  production: true,
  env: 'production',

  // Disable Mock API HttpClient interceptor
  enableMockApi: false,

  // App's public URL
  publicUrl: 'https://membership.usacycling.org',

  // API URL (no trailing slash allowed)
  apiUrl: 'https://laravel-api.usacycling.org/api/v1',
  loginUrl: 'https://myaccount.usacycling.org/login',
  logoutUrl: 'https://laravel-api.usacycling.org/logout',

  eventSearchUrl: 'https://usacycling.org/events',

  // Shopify API (no trailing slash allowed)
  shopifyStoreUrl: 'https://usa-cycling.myshopify.com',
  shopifyAdminUrl: 'https://usa-cycling.myshopify.com/admin/',
  shopifyAccessToken: 'abd5afa5696f76a48076500d558aff1e',
  shopifyApiVersion: '2025-01',

  // External Links
  adminUrl: 'https://myaccount.usacycling.org',
  accountUrl: 'https://myaccount.usacycling.org',
  checkoutUrl: 'https://checkout.usacycling.org',
  mainUrl: 'https://usacycling.org',

  // Airbrake
  airbrake: {
    id: 265329,
    key: '1f5efdba11bc7f9bf5861abeeb23f693',
  },

  // Google Tag Manager ID
  gtmId: 'GTM-56HG83Q',

  // Site key for Google reCAPTCHA
  recaptchaSiteKey: '6Lf8nx8pAAAAALatSnhN8Lr4plmqFVVXgrun-NOk',
};
