import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  License,
  LicenseCategory,
  LicenseTypeId,
  Membership,
  MembershipOrder,
  MembershipStatus,
  MerchantAccountTag,
  PersonTypes,
  Product,
  ProductVariant,
  Profile,
  Registration,
  SpotClassOneIds,
} from 'src/app/core/models';
import {
  AuthService,
  ProfileService,
  RegistrationService,
  ShopifyService,
  TransactionService,
} from 'src/app/core/services';
import { UpgradeInfoModalComponent } from 'src/app/enrollment/upgrade-info-modal/upgrade-info-modal.component';
import { NonEnrollmentWaiverModalComponent } from 'src/app/shared/non-enrollment-waiver-modal/non-enrollment-waiver-modal.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-race-upgrade',
  templateUrl: './race-upgrade.component.html',
  styleUrls: ['./race-upgrade.component.scss'],
})
export class RaceUpgradeComponent implements OnInit {
  get spotClassOne(): boolean {
    return (
      this.user.spot_customer_json_data &&
      this.user.spot_customer_json_data.some((data) =>
        [
          SpotClassOneIds.STAGING_ID_2022,
          SpotClassOneIds.PRODUCTION_ID_2022,
          SpotClassOneIds.STAGING_ID_2023,
          SpotClassOneIds.PRODUCTION_ID_2023,
        ].includes(data.event_id)
      )
    );
  }

  get isRaceAge18(): boolean {
    return this.user.profile_race_age === 18;
  }

  get hasInternationalLicense(): boolean {
    return this.hasLicense(LicenseTypeId.INTERNATIONAL);
  }

  get hasProLicense(): boolean {
    return this.hasLicense(LicenseTypeId.PROFESSIONAL);
  }

  get hasCollegiateLicense(): boolean {
    return this.hasLicense(LicenseTypeId.COLLEGIATE);
  }

  get upgradeOptions(): any[] {
    if (this.isRide) {
      return this.upgradeOptionsMap.ride;
    }
    if (this.isStandard) {
      return this.upgradeOptionsMap.standard;
    }

    const key = this.isRacePlus ? 'racePlus' : 'race';
    let options = this.upgradeOptionsMap[key];

    if (this.hasCollegiateLicense) {
      options = options.filter((option: any) => option.type !== 'collegiate');
      if (this.isRace) {
        options.find((option: any) => option.type === 'race-plus').hideAutoRenew = true;
      }
    }

    if (this.hasInternationalLicense || this.hasProLicense) {
      options = options.filter((option: any) => option.type !== 'international');
    }

    if (this.isRace && this.hasInternationalLicense) {
      options.find((option: any) => option.type === 'race-plus').title = 'International+ Add On';
    }

    return options;
  }

  get loggedIn(): boolean {
    return !!this.user.profile_id;
  }

  get ghostModeActive(): boolean {
    return this.profileService.hasGhostModeCookie;
  }

  get showLoader(): boolean {
    return this.loggedIn && (this.isLoading || this.isSubmitting);
  }

  get personType(): PersonTypes {
    return this.loggedIn ? this.user.profile_person_type : null;
  }

  get isAdult(): boolean {
    return this.personType === PersonTypes.ADULT;
  }

  get isJunior(): boolean {
    return this.personType === PersonTypes.JUNIOR;
  }

  get activeMembership(): Membership {
    return (
      this.memberships &&
      this.memberships.find(
        (membership) => membership.membership_status === MembershipStatus.Active
      )
    );
  }

  get hasActiveMembership(): boolean {
    return !!this.activeMembership;
  }

  get isStandard(): boolean {
    return (
      this.hasActiveMembership &&
      this.activeMembership.membership_level === 1 &&
      !this.user.should_show_plus_designation
    );
  }

  get isRide(): boolean {
    return (
      this.hasActiveMembership &&
      this.activeMembership.membership_level === 1 &&
      this.user.should_show_plus_designation
    );
  }

  get isRace(): boolean {
    return (
      this.hasActiveMembership &&
      this.activeMembership.membership_level === 2 &&
      !this.user.should_show_plus_designation
    );
  }

  get isRacePlus(): boolean {
    return (
      this.hasActiveMembership &&
      this.activeMembership.membership_level === 2 &&
      this.user.should_show_plus_designation
    );
  }

  get ineligibilityText(): string {
    return this.activeMembership
      ? 'There are no upgrades available for your current membership.'
      : 'You do not currently have an active membership. Sign up today!';
  }

  get ineligibilityBtnText(): string {
    return this.activeMembership ? 'Go to My Account' : 'Get a Membership';
  }

  constructor(
    private authService: AuthService,
    private modal: NgbModal,
    private profileService: ProfileService,
    private registrationService: RegistrationService,
    private router: Router,
    private shopifyService: ShopifyService,
    private transactionService: TransactionService
  ) { }

  get profileLicenses(): License[] {
    return this.user?.licenses;
  }
  isLoading = true;
  raceDependenciesLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isSubmitting = false;
  memberships: any[];
  serverError: any = null;
  upgradeForm: FormGroup;
  user: Profile;
  selectedLicenses: LicenseCategory[] = [];

  registration: Registration;
  cartOrder: MembershipOrder;
  variantsBySku: { [key: string]: ProductVariant };
  collegiateSku = 'COL-AO';
  internationalSku: string;
  upgradeSku: string;
  waiverSigned: boolean;

  ridePlusBenefits = ['24/7 on-the-the bike coverage'];
  raceBenefits = ['Race in your category in all disciplines'];
  racePlusBenefits = ['24/7 on-the-the bike coverage', 'Get $0 deductible on-the-bike accident coverage'];
  collegiateBenefits = ['Discounted Race License', 'Access to Collegiate races'];
  internationalBenefits = ['Required for UCI races', 'Required for International racing'];
  internationalPlusBenefits = [
    'Required for UCI & International racing',
    '24/7 on-the-the bike coverage',
  ];
  usingNewApiVersion: boolean = environment.shopifyApiVersion >= '2025-01';

  planSkus = this.shopifyService.planSkus;

  upgradeOptionsMap: any = {
    ride: [
      {
        title: 'Race+ AddOn',
        type: 'ride-race-plus',
        perYear: true,
        sku: 'RACE-PLUS-RIDE-UP',
        benefits: this.racePlusBenefits,
        boldBorder: true,
        spotItem: true,
        autoRenew: true,
        hideAutoRenew: false,
      },
    ],
    race: [
      {
        title: 'Race+ AddOn',
        type: 'race-plus',
        sku: this.planSkus.racePlusUpgrade,
        perYear: true,
        benefits: this.racePlusBenefits,
        boldBorder: true,
        spotItem: true,
        autoRenew: true,
        hideAutoRenew: false,
      },
      {
        title: 'Collegiate License',
        type: 'collegiate',
        sku: this.planSkus.collegiateUpgrade,
        perYear: true,
        benefits: this.collegiateBenefits,
        boldBorder: false,
        spotItem: false,
        autoRenew: false,
        hideAutoRenew: true,
      },
      {
        title: 'International License',
        type: 'international',
        perYear: false,
        benefits: this.internationalBenefits,
        boldBorder: false,
        spotItem: false,
        autoRenew: true,
        hideAutoRenew: false,
      },
    ],
    racePlus: [
      {
        title: 'Collegiate+ License',
        type: 'collegiate',
        perYear: true,
        sku: this.planSkus.collegiateUpgrade,
        benefits: this.collegiateBenefits,
        boldBorder: false,
        spotItem: false,
        autoRenew: false,
        hideAutoRenew: true,
      },
      {
        title: 'International+ License',
        type: 'international',
        perYear: false,
        benefits: this.internationalPlusBenefits,
        boldBorder: false,
        spotItem: false,
        autoRenew: true,
        hideAutoRenew: false,
      },
    ],
    standard: [
      {
        title: 'Ride+ Upgrade',
        type: 'ride-plus',
        sku: this.planSkus.ridePlusUpgrade,
        benefits: this.ridePlusBenefits,
        border: true,
        spotItem: true,
        autoRenew: true,
        hideAutoRenew: false,
      },
      {
        title: 'Race Upgrade',
        type: 'race-standard',
        sku: 'RACE-UP-RIDE-UP',
        benefits: this.raceBenefits,
        border: true,
        spotItem: false,
        autoRenew: true,
        hideAutoRenew: false,
      },
    ],
  };

  getPrice(sku: string): string | number {
    if (this.variantsBySku && this.variantsBySku[sku]) {
      return this.variantsBySku[sku].priceV2.amount;
    }
    return 0;
  }

  private setVariantsBySku(products: Product[]): void {
    this.variantsBySku = products
      .flatMap((product) => product.variants)
      .reduce((acc, productVariant) => {
        acc[productVariant.sku] = productVariant;
        return acc;
      }, {});
  }

  ngOnInit(): void {
    this.planSkus = this.shopifyService.planSkus;
    this.raceDependenciesLoaded.subscribe((loaded) => loaded && this.setPrices());
    this.shopifyService.getProducts().then((products) => this.setVariantsBySku(products));
    this.authService.currentUser$.subscribe((user: Profile) => {
      this.user = user;
      if (this.loggedIn) {
        this.profileService.getProfileMemberships(user.profile_id).subscribe((memberships) => {
          this.memberships = memberships;
          this.isLoading = false;
          this.setRaceDependenciesLoaded();
        });

        this.registrationService
          .getRegistrationByProfile(this.user.profile_id)
          .subscribe((registration: Registration) => {
            this.registration = registration;
            this.setRaceDependenciesLoaded();
          });
      }
    });
  }

  private setRaceDependenciesLoaded(): void {
    this.raceDependenciesLoaded.next(
      !!this.memberships && !!this.registration && !!this.variantsBySku
    );
  }

  private setInternationalPrice(): void {
    this.internationalSku = this.setInternationalSku();
    const price = this.variantsBySku[this.internationalSku].priceV2.amount;
    const internationalOption = this.upgradeOptions.find(
      (option) => option.type === 'international'
    );
    if (internationalOption) {
      internationalOption.price = price;
      internationalOption.sku = this.internationalSku;
      if (this.isJunior) {
        internationalOption.perYear = true;
      }
    }
  }

  private setPrices(): void {
    this.upgradeOptions.forEach((item) => {
      if (!item.price && item.sku) {
        item.price = this.variantsBySku[item.sku].priceV2.amount;
      }
    });
    this.setInternationalPrice();
  }

  selectUpgrade(item: any): void {
    const { type, sku, is_auto_renewal } = item;

    switch (type) {
      case 'race-plus':
        this.selectRacePlus(item);
        break;
      case 'collegiate':
      case 'international':
        this.openUpgradeInfoModal(item);
        break;
      case 'ride-race-plus':
        this.user.profile_race_gender
          ? this.openWaiverModal('online_waiver', type, sku, is_auto_renewal)
          : this.openUpgradeInfoModal(item);
        break;
      case 'race-standard':
        this.user.profile_race_gender
          ? this.openWaiverModal('online_waiver', type, sku, is_auto_renewal)
          : this.openUpgradeInfoModal(item);
        break;
      default:
        this.checkout(type, sku, is_auto_renewal);
    }
  }

  selectRacePlus(item: any): void {
    const { has_spot_insurance, is_auto_renewal, sku, type } = item;
    if (this.registration) {
      this.registrationService
        .updateOptions(this.registration.id, { has_spot_insurance, is_auto_renewal })
        .subscribe((updatedRegistration) => {
          this.registration = updatedRegistration;
          this.checkout(type, sku, is_auto_renewal);
        });
    } else {
      this.openUpgradeInfoModal(item);
    }
  }

  openUpgradeInfoModal(item: any): void {
    const { title, type, sku, is_auto_renewal } = item;
    const modalRef = this.modal.open(UpgradeInfoModalComponent, {
      size: 'lg',
      centered: true,
    });

    modalRef.componentInstance.title = title;
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.autoRenew = is_auto_renewal;
    modalRef.componentInstance.user = this.user;
    modalRef.componentInstance.registration = this.registration;

    modalRef.result
      .then((registration: Registration) => {
        if (registration) {
          this.registration = registration;
        }

        const waiverName = type === 'international' ? 'international_waiver' : 'online_waiver';
        this.ghostModeActive || type === 'collegiate'
          ? this.checkout(type, sku, is_auto_renewal)
          : this.openWaiverModal(waiverName, type, sku, is_auto_renewal);
      })
      .catch(() => { });
  }

  login(): void {
    this.authService.doLogin(window.location.href);
  }

  private setRacePlusSku(): string {
    if (this.hasInternationalLicense) {
      if (this.isAdult) {
        return 'INT-PLUS-INT-UP';
      }
      if (this.isJunior) {
        return 'INT-PLUS-JUN-INT-UP';
      }
    }
    const skuParts: string[] = ['RACE-PLUS'];
    const { is_collegiate_cyclist } = this.registration;
    if (is_collegiate_cyclist) {
      skuParts.push('COL');
    } else if (this.user.profile_person_type === PersonTypes.JUNIOR) {
      skuParts.push('JUN');
    } else {
      skuParts.push('RACE');
    }
    skuParts.push('UP');
    return skuParts.join('-');
  }

  private setInternationalSku(): string {
    const skuParts: string[] = ['INT'];
    const { profile_person_type } = this.user;
    const { has_spot_insurance, is_collegiate_cyclist, is_spot_paid } = this.registration;
    if (has_spot_insurance && this.user.should_show_plus_designation) {
      skuParts.push('PLUS');
    }
    if (is_collegiate_cyclist) {
      skuParts.push(is_spot_paid ? 'RACE-PLUS' : 'RACE');
      skuParts.push('COL-UP');
    } else if (profile_person_type === PersonTypes.JUNIOR) {
      skuParts.push('JUN');
      skuParts.push(is_spot_paid ? 'RACE-PLUS-UP' : 'RACE-UP');
    } else {
      skuParts.push(has_spot_insurance && this.user.should_show_plus_designation ? 'RACE-PLUS-UP' : 'RACE-UP');
    }
    return skuParts.join('-');
  }

  private setUpgradeSku(type: string, sku?: string): string {
    switch (type) {
      case 'race-plus':
        return this.setRacePlusSku();
      case 'collegiate':
        return this.collegiateSku;
      case 'international':
        return this.internationalSku;
      default:
        return sku ? sku : null;
    }
  }

  openWaiverModal(waiverName: string, type: string, sku: string, is_auto_renewal: boolean): void {
    if (!this.ghostModeActive || !this.waiverSigned) {
      const modal = this.modal.open(NonEnrollmentWaiverModalComponent, {
        centered: true,
        size: 'xl',
      });
      modal.componentInstance.user = this.user;
      modal.componentInstance.waiverName = waiverName;
      modal.result
        .then(() => {
          this.waiverSigned = true;
          this.checkout(type, sku, is_auto_renewal);
        })
        .catch(() => { });
    } else {
      this.checkout(type, sku, is_auto_renewal);
    }
  }

  private checkout(type: string, sku?: string, autoRenew?: boolean): void {
    this.isSubmitting = true;
    this.upgradeSku = this.setUpgradeSku(type, sku);

    const customAttributes: Array<any> = [
      {
        key: 'source',
        value: 'membership',
      },
      {
        key: 'return_url',
        value: environment.accountUrl,
      },
    ];

    const lineItem: any = {
      variantId: this.variantsBySku[this.upgradeSku].id,
      quantity: 1,
    };

    if (['ride-race-plus', 'race-standard'].includes(type)) {
      lineItem.customAttributes = [
        {
          key: 'recurring',
          value: autoRenew ? 'Yearly' : 'None',
        },
      ];
    }
    const lineItems: Array<any> = [lineItem];
    const note = `membership, ${type}-upgrade, ${MerchantAccountTag.Membership}`;

    if (this.usingNewApiVersion) {
      this.shopifyService
        .createCheckoutNew(lineItems, customAttributes, note)
        .then((checkoutModel) => {
          const transaction = { shopify_uuid: checkoutModel.cart.id };

          return new Promise((resolve, reject) => {
            this.transactionService
              .createStandardTransaction(transaction)
              .pipe(
                catchError((err) => {
                  this.isSubmitting = false;
                  this.serverError = err;
                  return throwError(err);
                })
              )
              .subscribe(
                () => {
                  window.location.href = this.transactionService.getCartUrl(checkoutModel.cart.id, {
                    email: this.user.profile_email,
                    firstName: this.user.profile_first_name,
                    lastName: this.user.profile_last_name,
                  });
                  resolve(true);
                },
                () => {
                  reject();
                }
              );
          });
        });
    } else {
      this.shopifyService
        .createCheckoutOld(lineItems, customAttributes, note)
        .then((checkoutModel) => {
          const transaction = { shopify_uuid: checkoutModel.checkout.id };

          return new Promise((resolve, reject) => {
            this.transactionService
              .createStandardTransaction(transaction)
              .pipe(
                catchError((err) => {
                  this.isSubmitting = false;
                  this.serverError = err;
                  return throwError(err);
                })
              )
              .subscribe(
                () => {
                  window.location.href = this.transactionService.getCheckoutUrl(
                    checkoutModel.checkout.id,
                    {
                      email: this.user.profile_email,
                      firstName: this.user.profile_first_name,
                      lastName: this.user.profile_last_name,
                    }
                  );
                  resolve(true);
                },
                () => {
                  reject();
                }
              );
          });
        });
    }
  }

  toAccount(): void {
    window.location.assign(environment.accountUrl);
  }

  toIndex(): void {
    this.router.navigateByUrl('');
  }

  logout(): void {
    // Logout user and clear state
    this.authService.doLogout().subscribe((resp) => {
      if (resp) {
        window.location.assign(environment.publicUrl);
      }
    });
  }

  ineligibilityBtnAction(): void {
    this.activeMembership ? this.toAccount() : this.toIndex();
  }

  private hasLicense(licenseType: LicenseTypeId): boolean {
    const now = new Date();
    const today = Date.parse(`${now.getMonth() + 1}/${now.getDate()}/${now.getFullYear()}`);

    return (
      !!this.profileLicenses &&
      this.profileLicenses.some(
        (license) =>
          Date.parse(license.expiration_date) >= today && license.license_type === licenseType
      )
    );
  }
}
