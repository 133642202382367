import { Injectable } from '@angular/core';
import qs from 'qs';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class TransactionService {
  constructor(protected apiService: ApiService) {}

  create(transaction: any): Observable<any> {
    return this.apiService.post('/registration/transactions', transaction);
  }

  createStandardTransaction(transaction: any): Observable<any> {
    return this.apiService.post('/transactions', transaction);
  }

  /**
   * This function will kick off the scheduler on the back-end so that it processes
   * completed registrations instantenously.
   */
  ping(shopifyUuid: string): Observable<any> {
    return this.apiService.get(`/registration/transactions/${shopifyUuid}`);
  }

  encodeBase64UrlSafe = (input: string): string => {
    return btoa(input).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
  };

  /**
   * Build checkout URL (using shopifyUuid)
   */
  getCartUrl(shopifyUuid: string, customerParams = {}): string {
    // DEPRECATED - Old method to remove "=" from end of base64 id
    // const id = shopifyUuid.substring(0, shopifyUuid.length - 1);
    // ORDER ID FORMAT: (expecting base64 in url route)
    // gid://shopify/Checkout/cd27bea7c9234dda759bebe5dfd89b64?key=1467b61b0bd5f5cd09a1b5c30408faf5
    const id = this.encodeBase64UrlSafe(shopifyUuid);
    const queryString = qs.stringify(customerParams, { addQueryPrefix: true });
    const url = `${environment.checkoutUrl}/billing/cart/${id}${queryString}`;
    return url;
  }
  getCheckoutUrl(shopifyUuid: string, customerParams = {}): string {
    // DEPRECATED - Old method to remove "=" from end of base64 id
    // const id = shopifyUuid.substring(0, shopifyUuid.length - 1);
    // ORDER ID FORMAT: (expecting base64 in url route)
    // gid://shopify/Checkout/cd27bea7c9234dda759bebe5dfd89b64?key=1467b61b0bd5f5cd09a1b5c30408faf5
    const id = btoa(shopifyUuid);
    const queryString = qs.stringify(customerParams, { addQueryPrefix: true });
    const url = `${environment.checkoutUrl}/billing/checkout/${id}${queryString}`;
    return url;
  }
}
