import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  License,
  LicenseTypeId,
  Membership,
  MembershipType,
  MerchantAccountTag,
  PersonTypes,
  Product,
  ProductVariant,
  Profile,
} from 'src/app/core';
import { FamilyMembershipFacade, Plans, RegistrationFacade } from 'src/app/core/facades';
import {
  AuthService,
  BraintreeService,
  RegistrationService,
  ShopifyService,
  TransactionService,
} from 'src/app/core/services';
import { environment } from 'src/environments/environment';

import { NonEnrollmentWaiverModalComponent } from '../non-enrollment-waiver-modal/non-enrollment-waiver-modal.component';

@Component({
  selector: 'app-renew-membership-modal',
  templateUrl: './renew-membership-modal.component.html',
  styleUrls: ['./renew-membership-modal.component.scss'],
})
export class RenewMembershipModalComponent implements OnInit {
  benefits = [
    'Support for USA Cycling’s work in service of American cyclists',
    'Access to VIP benefits at America’s top cycling events',
    'A license to participate in hundreds of USA Cycling races nationwide',
    'A 12-month rolling membership',
    'Custom name decals, discounts on products and services, legal support, and free coaching consultation',
  ];

  @Input() profile: Profile;
  @Input() password: any;
  @Input() currentMembership: Membership;
  @Input() expirationDate: Date;
  @Input() isExpired: boolean;
  @Input() addedJunior: boolean;
  @Input() createMethod = 'login';

  isSubmitting = false;
  displayError = false;
  variantsBySku: { [key: string]: ProductVariant };
  planSkus = this.shopifyService.planSkus;
  usersSubscription: any;
  subsLoading = false;
  isEarlyRenewalMonthForInternational = false;
  usingNewApiVersion: boolean = environment.shopifyApiVersion >= '2025-01';

  memberships = {
    ride: {
      title: 'Ride',
      type: 'ride-standard',
      price: this.rideLitePrice,
      sku: this.planSkus.rideLite,
      border: true,
      spotItem: false,
    },
    ridePlus: {
      title: 'Ride+',
      type: 'ride-plus',
      price: this.ridePlusPrice,
      sku: this.planSkus.ride,
      border: true,
      spotItem: true,
    },
    race: {
      title: 'Race',
      type: 'race-standard',
      price: this.raceStandardPrice,
      sku: this.planSkus.adult.race,
      border: true,
      spotItem: false,
    },
    racePlus: {
      title: 'Race+',
      type: 'ride-race-plus',
      price: this.racePlusPrice,
      perYear: true,
      sku: this.planSkus.adult.racePlus,
    },
    international: {
      title: 'International',
      type: 'international',
      sku: this.planSkus.adult.international,
    },
    internationalPlus: {
      title: 'International Place',
      type: 'international',
      sku: this.planSkus.adult.internationalPlus,
    },
    collegiate: {
      title: 'Collegiate',
      type: 'collegiate',
      sku: this.planSkus.raceCollegiate,
    },
    collegiatePlus: {
      title: 'Collegiate Pluse',
      type: 'collegiate',
      sku: this.planSkus.racePlusCollegiate,
    },
  };

  constructor(
    public activeModal: NgbActiveModal,
    private shopifyService: ShopifyService,
    private familyFacade: FamilyMembershipFacade,
    private registrationFacade: RegistrationFacade,
    private registrationService: RegistrationService,
    private authService: AuthService,
    private router: Router,
    private modal: NgbModal,
    private braintreeService: BraintreeService,
    private transactionService: TransactionService
  ) {}

  ngOnInit(): void {
    this.subsLoading = true;
    this.getUsersSubscription();
    this.shopifyService.getProducts().then((products) => this.setVariantsBySku(products));
    this.registrationService.isEarlyRenewalMonthForInternational().subscribe((data: any) => {
      if (data && data.month) {
        this.isEarlyRenewalMonthForInternational =
          data.month == new Date().toLocaleString('default', { month: 'long' });
      }
    });
  }

  getUsersSubscription(): void {
    this.braintreeService.getSubscriptions().subscribe((resp) => {
      this.subsLoading = false;
      if (resp) {
        this.usersSubscription = resp.subscriptions;
      }
    });
  }

  private setVariantsBySku(products: Product[]): void {
    this.variantsBySku = products
      .flatMap((product) => product.variants)
      .reduce((acc, productVariant) => {
        acc[productVariant.sku] = productVariant;
        return acc;
      }, {});
  }

  get raceStandardPrice(): string | number {
    return this.variantsBySku && this.variantsBySku[this.planSkus.adult.race].priceV2.amount;
  }

  get racePlusPrice(): string | number {
    return this.variantsBySku && this.variantsBySku[this.planSkus.adult.racePlus].priceV2.amount;
  }
  get rideLitePrice(): string | number {
    return this.variantsBySku && this.variantsBySku[this.planSkus.rideLite].priceV2.amount;
  }

  get ridePlusPrice(): string | number {
    return this.variantsBySku && this.variantsBySku[this.planSkus.ride].priceV2.amount;
  }

  get daysTilExpiration(): any {
    const today = new Date().toISOString();
    if (!this.currentMembership) {
      return null;
    }

    const expiration = moment(this.currentMembership.membership_expiration_date).format(
      'YYYY-MM-DD'
    );
    const current_date = moment(today).format('YYYY-MM-DD');
    const days = moment(expiration).diff(current_date, 'days');

    return days;
  }

  get shouldUseInternationalPath2024(): boolean {
    return this.isEarlyRenewalMonthForInternational && this.hasInternationalLicense;
  }

  get hasMultipleBillingCycles(): boolean {
    return this.usersSubscription && this.usersSubscription.length
      ? this.usersSubscription[0].numberOfBillingCycles >
          this.usersSubscription[0].currentBillingCycle ||
          this.usersSubscription[0].numberOfBillingCycles === null
      : false;
  }

  // all licenses ARE renewable if expired or within the early renew time window.
  get isRenewable(): boolean {
    return this.daysTilExpiration < 0 || this.isEarlyRenewable;
  }

  get notRenewable(): boolean {
    if (this.shouldUseInternationalPath2024) {
      return !this.isRenewable;
    } else {
      if (this.usersSubscription && !this.usersSubscription.length) {
        return this.daysTilExpiration >= 0;
      }
      return this.daysTilExpiration > 30;
    }
  }

  get isEarlyRenewable(): boolean {
    if (this.shouldUseInternationalPath2024) {
      return 0 <= this.daysTilExpiration && this.daysTilExpiration <= 30;
    } else {
      return this.usersSubscription && this.usersSubscription.length
        ? 0 <= this.daysTilExpiration &&
            this.daysTilExpiration <= 30 &&
            !this.hasMultipleBillingCycles
        : false;
    }
  }

  get isUserAdminHold(): boolean {
    return this.profile.profile_has_admin_hold;
  }

  get isUserSuspended(): boolean {
    return this.profile.profile_is_suspended;
  }

  get membershipTextClass(): string {
    return this.isExpired ? 'expired-status' : '';
  }

  get hasPremiumMembership(): boolean {
    return this.currentMembership.membership_level_name === 'Premium';
  }

  get profilePath(): string {
    return `profile/${this.profile.profile_id}`;
  }

  get adminMembershipLink(): string {
    return `${environment.accountUrl}/${this.profilePath}/membership`;
  }

  get blockEarlyRenewal(): boolean {
    return false;
    /*
    const today = new Date();
    const firstDayOfNextYear = new Date('2024-01-01T00:00:00.000-05:00');
    const membershipExpirationDate = new Date(this.currentMembership.membership_expiration_date);
    // check if user has spot membership and block them from early renew if membership expires after ther new year (going into 2024)
    // if today is after 01/01/01 then we do not block them from early renew
    return (
      this.currentMembership.has_spot_insurance &&
      today.getTime() < firstDayOfNextYear.getTime() &&
      membershipExpirationDate.getTime() > firstDayOfNextYear.getTime()
    );
    */
  }

  redirectToUpgradePage(): void {
    this.router.navigate(['/enrollment/upgrade']);
    this.activeModal.close({ skipAction: true });
  }

  redirectToRenewalUpgradePage(): void {
    this.router.navigate(['/enrollment/upgrade/early-renewal']);
    this.activeModal.close({ skipAction: true });
  }

  redirectToAdminPage(): void {
    window.location.assign(this.adminMembershipLink);
  }

  logout(): void {
    // Logout user and clear state
    this.authService.doLogout().subscribe((resp) => {
      if (resp) {
        this.activeModal.dismiss();
      }
    });
  }

  renew(e: any): void {
    e.preventDefault();
    this.isSubmitting = true;
    this.displayError = false;

    if (!this.profile || !this.profile.profile_id) {
      return;
    }
    const personType =
      this.profile.profile_person_type === PersonTypes.JUNIOR
        ? PersonTypes.JUNIOR
        : PersonTypes.ADULT;

    if (this.createMethod === 'login') {
      this.registrationService
        .registrationCreateFromLogin(this.profile.profile_id, this.password)
        .pipe(
          catchError((error) => {
            this.isSubmitting = false;
            this.displayError = true;
            return throwError(error);
          })
        )
        .subscribe((data) => {
          this.processRegistrationData(data, personType);
        });
    } else {
      const payload = this.addedJunior
        ? { membershipType: 2, orderId: this.registrationFacade.currentState.orderId }
        : {};
      this.registrationService
        .registrationCreateFromProfile(this.profile.profile_id, payload)
        .pipe(
          catchError((error) => {
            this.isSubmitting = false;
            this.displayError = true;
            return throwError(error);
          })
        )
        .subscribe((data) => {
          this.processRegistrationData(data, personType);
        });
    }
  }

  get profileLicenses(): License[] {
    return this.profile.licenses;
  }

  get hasInternationalLicense(): boolean {
    return this.hasLicense(LicenseTypeId.INTERNATIONAL);
  }

  get hasProLicense(): boolean {
    return this.hasLicense(LicenseTypeId.PROFESSIONAL);
  }

  get hasCollegiateLicense(): boolean {
    return this.hasLicense(LicenseTypeId.COLLEGIATE);
  }

  hasLicense(licenseType: LicenseTypeId): boolean {
    const now = new Date();
    const today = Date.parse(`${now.getMonth() + 1}/${now.getDate()}/${now.getFullYear()}`);

    return this.profileLicenses.some(
      (license) =>
        Date.parse(license.expiration_date) >= today && license.license_type === licenseType
    );
  }

  checkout(): void {
    let membership: any;
    if (
      this.currentMembership.membership_level === 1 &&
      this.currentMembership.has_spot_insurance === false
    ) {
      membership = this.memberships.ride;
    }
    if (
      this.currentMembership.membership_level === 1 &&
      this.currentMembership.has_spot_insurance === true
    ) {
      membership = this.memberships.ridePlus;
    }
    if (
      this.currentMembership.membership_level === 2 &&
      this.currentMembership.has_spot_insurance === true
    ) {
      membership = this.memberships.racePlus;
    }
    if (
      this.currentMembership.membership_level === 2 &&
      this.currentMembership.has_spot_insurance === true &&
      this.hasInternationalLicense
    ) {
      membership = this.memberships.internationalPlus;
    }
    if (
      this.currentMembership.membership_level === 2 &&
      this.currentMembership.has_spot_insurance === true &&
      this.hasCollegiateLicense
    ) {
      membership = this.memberships.collegiatePlus;
    }
    if (
      this.currentMembership.membership_level === 2 &&
      this.currentMembership.has_spot_insurance === false
    ) {
      membership = this.memberships.race;
    }
    if (
      this.currentMembership.membership_level === 2 &&
      this.currentMembership.has_spot_insurance === false &&
      this.hasInternationalLicense
    ) {
      membership = this.memberships.international;
    }
    if (
      this.currentMembership.membership_level === 2 &&
      this.currentMembership.has_spot_insurance === false &&
      this.hasCollegiateLicense
    ) {
      membership = this.memberships.collegiate;
    }
    // check to see if this a junior. if they have a collegiate license then use regular sku since there is no junior collegiate sku
    if (
      this.profile.profile_person_type === PersonTypes.JUNIOR &&
      !this.profile.profile_is_pro &&
      !this.hasCollegiateLicense
    ) {
      membership.sku += '-JUN';
    }
    this.renewCurrentMembership(membership);
    // this.checkout(type, sku);
  }

  renewCurrentMembership(item: any): void {
    // const { sku } = item;
    // this.openWaiverModal('online_waiver', sku);
    const { type, sku } = item;
    this.openWaiverModal('online_waiver', type, sku, false);
  }

  openWaiverModal(waiverName: string, type: string, sku: string, is_auto_renewal: boolean): void {
    const modal = this.modal.open(NonEnrollmentWaiverModalComponent, {
      centered: true,
      size: 'xl',
    });
    modal.componentInstance.user = this.profile;
    modal.componentInstance.waiverName = waiverName;
    modal.result
      .then(() => {
        this.toCheckout(type, sku, is_auto_renewal);
      })
      .catch(() => {});
  }

  private earlyRenewalIntPath2024(type: string, sku?: string, autoRenew?: boolean): void {
    this.isSubmitting = true;

    const customAttributes: Array<any> = [
      {
        key: 'source',
        value: 'membership',
      },
      {
        key: 'return_url',
        value: environment.accountUrl,
      },
      {
        key: 'recurring',
        value: autoRenew ? 'Yearly' : 'None',
      },
    ];

    const lineItem: any = {
      // variant: this.variantsBySku[sku],
      variantId: this.variantsBySku[sku].id,
      quantity: 1,
    };

    lineItem.customAttributes = customAttributes;

    // lineItem.customAttributes = [
    //   {
    //     key: 'recurring',
    //     value: autoRenew ? 'Yearly' : 'None',
    //   },
    // ];

    const lineItems: Array<any> = [lineItem];
    const note = `membership, ${type}-upgrade, ${MerchantAccountTag.Membership}`;

    // const strigifiedLineItems = JSON.stringify(lineItems);
    // const encodedLineItems = btoa(strigifiedLineItems);

    // window.location.href = `${environment.checkoutUrl}/confirmation/billing/${encodedLineItems}`;

    if (this.usingNewApiVersion) {
      this.shopifyService
        .createCheckoutNew(lineItems, customAttributes, note)
        .then((checkoutModel) => {
          const transaction = { shopify_uuid: checkoutModel.cart.id };

          return new Promise((resolve, reject) => {
            this.transactionService
              .createStandardTransaction(transaction)
              .pipe(
                catchError((err) => {
                  this.isSubmitting = false;
                  return throwError(err);
                })
              )
              .subscribe(
                () => {
                  window.location.href = this.transactionService.getCartUrl(checkoutModel.cart.id, {
                    email: this.profile.profile_email,
                    firstName: this.profile.profile_first_name,
                    lastName: this.profile.profile_last_name,
                  });
                  resolve(true);
                },
                () => {
                  reject();
                }
              );
          });
        });
    } else {
      this.shopifyService
        .createCheckoutOld(lineItems, customAttributes, note)
        .then((checkoutModel) => {
          const transaction = { shopify_uuid: checkoutModel.checkout.id };

          return new Promise((resolve, reject) => {
            this.transactionService
              .createStandardTransaction(transaction)
              .pipe(
                catchError((err) => {
                  this.isSubmitting = false;
                  return throwError(err);
                })
              )
              .subscribe(
                () => {
                  window.location.href = this.transactionService.getCheckoutUrl(
                    checkoutModel.checkout.id,
                    {
                      email: this.profile.profile_email,
                      firstName: this.profile.profile_first_name,
                      lastName: this.profile.profile_last_name,
                    }
                  );
                  resolve(true);
                },
                () => {
                  reject();
                }
              );
          });
        });
    }
  }

  private toConfirmation(sku?: string, autoRenew?: boolean): void {
    this.isSubmitting = true;

    // const customAttributes: Array<any> = [
    //   {
    //     key: 'source',
    //     value: 'membership',
    //   },
    //   {
    //     key: 'return_url',
    //     value: environment.accountUrl,
    //   },
    // ];

    const lineItem: any = {
      variant: this.variantsBySku[sku],
      quantity: 1,
    };

    lineItem.customAttributes = [
      {
        key: 'recurring',
        value: autoRenew ? 'Yearly' : 'None',
      },
    ];

    const lineItems: Array<any> = [lineItem];
    // const note = `membership, ${type}-upgrade, ${MerchantAccountTag.Membership}`;

    const strigifiedLineItems = JSON.stringify(lineItems);
    const encodedLineItems = btoa(strigifiedLineItems);

    window.location.href = `${environment.checkoutUrl}/confirmation/billing/${encodedLineItems}`;

    // this.shopifyService.createCheckout(lineItems, customAttributes, note).then((checkoutModel) => {
    //   const transaction = { shopify_uuid: checkoutModel.checkout.id };

    //   return new Promise((resolve, reject) => {
    //     this.transactionService
    //       .createStandardTransaction(transaction)
    //       .pipe(
    //         catchError((err) => {
    //           this.isSubmitting = false;
    //           return throwError(err);
    //         })
    //       )
    //       .subscribe(
    //         () => {
    // window.location.href = this.transactionService.getCheckoutUrl(
    //   checkoutModel.checkout.id,
    //   {
    //     email: this.profile.profile_email,
    //     firstName: this.profile.profile_first_name,
    //     lastName: this.profile.profile_last_name,
    //   }
    // );
    //     resolve(true);
    //   },
    //             () => {
    //   reject();
    // }
    //           );
    //       });
    //     });
  }

  private toCheckout(type: string, sku?: string, autoRenew?: boolean): void {
    this.isSubmitting = true;

    if (this.isEarlyRenewalMonthForInternational && sku && sku.includes('INT')) {
      this.earlyRenewalIntPath2024(type, sku, autoRenew);
    } else {
      this.toConfirmation(sku);
    }
  }

  private processRegistrationData(data: any, personType: PersonTypes): void {
    const registration = data.enrollment;
    if (registration.membership_type === MembershipType.Family) {
      this.registrationFacade.updatePlan(Plans.FAMILY);
      const currentFamilyState = this.familyFacade.currentState;

      const familyMembers = [...currentFamilyState.familyMembers];
      const currentMemberIndex = familyMembers.indexOf(
        familyMembers.find((member) => member.is_selected)
      );
      const currentFamilyMember = familyMembers[currentMemberIndex];

      if (currentFamilyMember) {
        currentFamilyMember.id = registration.id;
        currentFamilyMember.first_name = registration.first_name;
        currentFamilyMember.last_name = registration.last_name;
        currentFamilyMember.email = registration.email;
        currentFamilyMember.person_type = personType;
      }

      const selectedMember = {
        ...currentFamilyState.selectedMember,
      };

      selectedMember.id = registration.id;
      selectedMember.first_name = registration.first_name;
      selectedMember.last_name = registration.last_name;
      selectedMember.email = registration.email;
      selectedMember.person_type = personType;

      this.familyFacade.updateState({
        ...currentFamilyState,
        familyMembers,
        selectedMember,
      });
    }

    this.registrationFacade.updateMemberInfoFilled(true);
    this.registrationFacade.updateIsRenewingPremium(
      registration.is_premium && registration.person_type !== PersonTypes.JUNIOR
    );
    this.registrationFacade.updateRegistration(registration);
    this.registrationFacade.updateOrderId(registration.order.order_id);

    this.activeModal.close(registration);
  }

  get renewInfoMessage(): string {
    let message = 'Your USA Cycling Membership ';
    message += this.isExpired ? 'is expired, ' : 'is current, ';
    message += this.notRenewable
      ? 'and there is no need to renew at this time.'
      : 'and you can renew at this time.';
    return message;
  }
}
