import { Injectable } from '@angular/core';
import GraphQLJSClient from 'graphql-js-client';

import { environment } from '../../../environments/environment';
import { Product, ProductTag, ProductVariant, Profile } from '../models';

@Injectable({
  providedIn: 'root',
})
export class ShopifyService {
  variantsBySku: { [key: string]: ProductVariant };
  // TODO: @Brennan - Make prices dependent on Shopify variants?
  standardPlanPrice = 50;
  juniorPlanPrice = 0;
  collegiatePlanPrice = 40;
  premiumUpgradePrice = 50;
  spotInsurancePrice = 40;
  familyPlanPrice = 75;
  internationalLicenseAdultPrice = 130;
  collegiateInternationalAdultPrice = 190;
  internationalLicenseJuniorPrice = 100;
  proLicensePrice = 200;
  podiumPackagePrice = 50;

  // maybe this holds skus so we can map them to shopify products
  planSkus = {
    rideLite: 'RIDE-LITE',
    ride: 'RIDE',
    ridePlusUpgrade: 'RIDE-RIDE-LITE-UP',
    rideTech: 'RIDE-TEC',
    raceCollegiate: 'RACE-COL',
    racePlusCollegiate: 'RACE-PLUS-COL',
    racePlusUpgrade: 'RACE-PLUS-RACE-UP',
    racePlusRideUpgrade: 'RACE-PLUS-RIDE-UP',
    raceStandardUpgrade: 'RACE-UP-RIDE-UP',
    professional: 'PRO',
    professionalPlus: 'PRO-PLUS',
    collegiateUpgrade: 'COL-AO',
    adult: {
      race: 'RACE',
      racePlus: 'RACE-PLUS',
      international: 'INT',
      internationalPlus: 'INT-PLUS',
      internationalFromRace: 'INT-RACE-UP',
      internationalPlusFromRacePlus: 'INT-PLUS-RACE-PLUS-UP',
    },
    junior: {
      race: 'RACE-JUN',
      racePlus: 'RACE-PLUS-JUN',
      international: 'INT-JUN',
      internationalPlus: 'INT-PLUS-JUN',
      internationalFromRace: 'INT-JUN-RACE-UP',
      internationalPlusFromRacePlus: 'INT-JUN-RACE-UP',
    },
  };

  odaSkuPrefixes = {
    tuition_housing: 'ODA-TUITION-HOUSING',
    // Use these instead if tuition & housing split into 2 products again
    // tuition: 'ODA-TUITION',
    // housing: 'ODA-HOUSING',
    clothing: 'ODA-CLOTHING',
  };
  typeBundle: any;
  client: any;

  constructor() {
    this.initializeClient();
  }

  private async initializeClient() {
    const isNewVersion = environment.shopifyApiVersion >= '2025-01';

    const { default: typeBundle } = isNewVersion
      ? await import('./shopify.bundle')
      : await import('./old-shopify.bundle');

    this.typeBundle = typeBundle;

    this.client = new GraphQLJSClient(this.typeBundle, {
      url: `${environment.shopifyStoreUrl}/api/${environment.shopifyApiVersion}/graphql`,
      fetcherOptions: {
        headers: {
          'X-Shopify-Storefront-Access-Token': environment.shopifyAccessToken,
        },
      },
    });
  }

  setProductsByVariantSku(): any {
    this.getProducts().then((products) => {
      this.setVariantsBySku(products);
    });
  }

  private setVariantsBySku(products: Product[]): void {
    this.variantsBySku = products
      .flatMap((product) => product.variants)
      .reduce((acc, productVariant) => {
        acc[productVariant.sku] = productVariant;
        return acc;
      }, {});
  }

  async getProducts(tags?: string[]): Promise<Product[]> {
    if (!this.client) {
      await this.initializeClient();
    }
    // Default to membership product tags, if tags not explicilty set
    tags = tags || ['membership', 'member-offers', 'donation', 'podium-package'];
    const tagQueryArray: string[] = [];
    tags.forEach((tag) => tagQueryArray.push(`tag:${tag}`));
    const tagQuery = tagQueryArray.join(' OR ');

    const query = this.client.query((root: any) => {
      root.addConnection('products', { args: { first: 100, query: tagQuery } }, (products: any) => {
        products.add('id');
        products.add('title');
        products.add('description');
        products.add('tags');
        products.addConnection('images', { args: { first: 250 } }, (images: any) => {
          images.add('id');
          images.add('altText');
          images.add('originalSrc');
        });
        products.addConnection('variants', { args: { first: 250 } }, (variants: any) => {
          variants.add('title');
          variants.add('sku');
          variants.add('priceV2', (price: any) => {
            price.add('amount');
          });
          variants.add('compareAtPriceV2', (compare: any) => {
            compare.add('amount');
          });
          variants.add('product', {}, (product: any) => {
            product.add('title');
          });
          variants.add(
            'metafield',
            { alias: 'description', args: { namespace: 'variant', key: 'description' } },
            (metafield: any) => {
              metafield.add('value');
            }
          );
        });
      });
    });
    return this.client.send(query).then(({ model }: any) => {
      return model.products;
    });
  }

  async createCheckoutNew(
    lineItems: Array<{ quantity: number; variantId: string }>, // Array of objects with quantity and variantId
    attributes: Array<any> = [],
    note?: string
  ): Promise<any> {
    const input = this.client.variable('input', 'CartInput');
    const mutation: any = this.client.mutation('cartCreateMutation', [input], (root: any) => {
      root.add('cartCreate', { args: { input } }, (cartCreate: any) => {
        cartCreate.add('cart', (cart: any) => {
          cart.add('id'); // Add cart ID
          cart.add('checkoutUrl'); // Add checkout URL
          cart.addConnection(
            'lines',
            { args: { first: 10 } }, // Fetch the first 10 line items
            (lineItemConnection: any) => {
              lineItemConnection.add('quantity');
              lineItemConnection.add('merchandise', (merchandise: any) => {
                merchandise.addInlineFragmentOn('ProductVariant', (variant: any) => {
                  variant.add('id');
                  variant.add('title');
                });
              });
            }
          );
        });
      });
    });

    // Convert lineItems to match Shopify's expected format
    const lines = lineItems.map((item) => ({
      quantity: item.quantity,
      merchandiseId: item.variantId, // Transform variantId into merchandiseId
    }));
    // Send the mutation
    try {
      const response = await this.client.send(mutation, {
        input: { lines, attributes, note },
      });

      if (!response) {
        throw new Error('No response received from Shopify API.');
      }

      const { model, errors } = response;

      if (errors && errors.length) {
        console.error('GraphQL Errors:', errors);
        throw new Error(`GraphQL Errors: ${JSON.stringify(errors)}`);
      }

      if (!model || !model.cartCreate) {
        throw new Error('cartCreate mutation failed or returned no data.');
      }

      return model.cartCreate;
    } catch (error) {
      console.error('Error during cartCreate mutation:', error);
      throw error;
    }
  }

  createCheckoutOld(
    lineItems: Array<any>,
    customAttributes: Array<any> = [],
    note?: string
  ): Promise<any> {
    const input = this.client.variable('input', 'CheckoutCreateInput!');
    const mutation = this.client.mutation('myMutation', [input], (root: any) => {
      root.add('checkoutCreate', { args: { input } }, (checkoutCreate: any) => {
        checkoutCreate.add('checkoutUserErrors', (checkoutUserErrors: any) => {
          checkoutUserErrors.add('message');
          checkoutUserErrors.add('field');
          checkoutUserErrors.add('code');
        });
        checkoutCreate.add('userErrors', (userErrors: any) => {
          userErrors.add('message');
          userErrors.add('field');
        });
        checkoutCreate.add('checkout', (checkout: any) => {
          checkout.add('id');
          checkout.add('webUrl');

          checkout.addConnection(
            'lineItems',
            { args: { first: 250 } },
            (lineItemsConnection: any) => {
              lineItemsConnection.add('variant', (variant: any) => {
                variant.add('title');
              }),
                lineItemsConnection.add('quantity');
            }
          );
        });
      });
    });
    return this.client
      .send(mutation, {
        input: { lineItems, customAttributes, note },
      })
      .then(({ model }: any) => model.checkoutCreate);
  }

  fetchCheckout(checkoutId: string): Promise<any> {
    const query = this.client.query((root: any) => {
      root.add('node', { args: { id: checkoutId }, alias: 'checkout' }, (node: any) => {
        node.add('id');
        node.addInlineFragmentOn('Checkout', (checkout: any) => {
          checkout.add('subtotalPrice');
          checkout.add('totalTax');
          checkout.add('totalPrice');

          checkout.addConnection('lineItems', { args: { first: 250 } }, (lineItems: any) => {
            lineItems.add('variant', (variant: any) => {
              variant.add('title');
              variant.add('image', (image: any) => image.add('src'));
              variant.add('price');
              variant.add('product', (product: any) => product.add('tags'));
            });
            lineItems.add('quantity');
          });
        });
      });
    });
    return this.client.send(query, { checkoutId }).then(({ model }: any) => model.checkout);
  }

  replaceLineItems(cartId: string, lineItemsInput: Array<any>): Promise<any> {
    const lineItemsForCheckout = lineItemsInput.map((item: any) => ({
      id: item.id,
      variantId: item.variantId,
      quantity: item.quantity,
    }));

    const checkoutId = this.client.variable('checkoutId', 'ID!');
    const lineItems = this.client.variable('lineItems', '[CheckoutLineItemInput!]!');
    const mutation = this.client.mutation('myMutation', [checkoutId, lineItems], (root: any) => {
      root.add(
        'checkoutLineItemsReplace',
        { args: { checkoutId, lineItems } },
        (checkoutLineItemsReplace: any) => {
          checkoutLineItemsReplace.add('userErrors', (userErrors: any) => {
            userErrors.add('message');
            userErrors.add('field');
          });
          checkoutLineItemsReplace.add('checkout', (checkout: any) => {
            checkout.add('webUrl');
            checkout.add('subtotalPrice');
            checkout.add('totalTax');
            checkout.add('totalPrice');

            checkout.addConnection(
              'lineItems',
              { args: { first: 250 } },
              (lineItemsConnection: any) => {
                lineItemsConnection.add('variant', (variant: any) => {
                  variant.add('title'),
                    variant.add('image', (image: any) => image.add('src')),
                    variant.add('price');
                }),
                  lineItemsConnection.add('quantity');
              }
            );
          });
        }
      );
    });

    return this.client.send(mutation, { checkoutId: cartId, lineItems: lineItemsForCheckout });
  }

  defaultVariant(product: Product): ProductVariant {
    return product.variants[0];
  }

  abbreviatedTitle(product: Product): string {
    return product.title.split(' (')[0];
  }

  odaProductType(product: Product): string {
    let type = '';
    Object.entries(this.odaSkuPrefixes).forEach(([key, value]) => {
      if (this.defaultVariant(product).sku.includes(value.toString())) {
        type = key;
      }
    });
    return type;
  }

  odaProductPaid(user: Profile, product: Product): boolean {
    const field = `profile_is_oda_${this.odaProductType(product)}_paid`;
    return user[field];
  }

  // Use this function instead if 2-semester structure reimplemented
  odaProductPaidForSemester(user: Profile, product: Product, semester: number): boolean {
    if (semester !== 1 && semester !== 2) {
      return false;
    }
    const semesterText = semester === 1 ? 'first' : 'second';
    const productType = this.odaProductType(product);
    if (productType === 'tuition' || productType === 'housing') {
      const field = `profile_is_oda_${productType}_${semesterText}_semester_paid`;
      return user[field];
    } else if (productType === 'clothing') {
      return user.profile_is_oda_clothing_paid;
    } else {
      return false;
    }
  }

  sortProducts(products: Product[]): Product[] {
    const sortable = products.filter((product) => product.tags.some((tag) => this.hasSortTag(tag)));
    const unsortable = products.filter(
      (product) => !product.tags.some((tag) => this.hasSortTag(tag))
    );
    sortable.sort((a, b) => this.sortTagValue(a) - this.sortTagValue(b));
    return sortable.concat(unsortable);
  }

  hasSortTag(tag: ProductTag): boolean {
    return RegExp(/^sort_[1-9]*$/).test(tag.value);
  }

  sortTagValue(product: Product): number {
    const sortTag = product.tags.find((tag) => this.hasSortTag(tag));
    return parseInt(sortTag.value.split('_')[1], 10);
  }
}
