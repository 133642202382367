import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin, from, throwError } from 'rxjs';
import { catchError, finalize, mergeMap, tap } from 'rxjs/operators';
import { RegistrationFacade } from 'src/app/core/facades';
import {
  Discipline,
  Interest,
  License,
  LicenseFlag,
  MembershipEnrollmentStatus,
  MembershipOrder,
  MembershipType,
  MerchantAccountTag,
  PersonTypes,
  Product,
  ProductVariant,
  Registration,
} from 'src/app/core/models';
import {
  InterestsService,
  MembershipOrdersService,
  RegistrationService,
  ShopifyService,
  TransactionService,
} from 'src/app/core/services';
import { environment } from 'src/environments/environment';

import { MemberInformationModalComponent } from '../member-information-modal/member-information-modal.component';
import { RaceCategoriesModalComponent } from '../race-categories-modal/race-categories-modal.component';

export interface OrderItem {
  label: string;
  price: number;
}

const atLeastOneSelected = (control: AbstractControl): { [key: string]: any } | null => {
  const truthy = control.value.filter((v: any) => !!v);
  return truthy.length === 0 ? { members: { value: control.value } } : null;
};

@Component({
  selector: 'app-post-purchase-cart',
  templateUrl: './post-purchase-cart.component.html',
  styleUrls: ['./post-purchase-cart.component.scss'],
})
export class PostPurchaseCartComponent implements OnInit {
  @Input() chosenDonationAmount: number;
  @Input() licenses: License[];
  @Input() order: MembershipOrder;
  @Input() registration: Registration;

  donationTiers = [
    {
      label: '$0',
      value: 0,
    },
    {
      label: '$10',
      value: 10,
    },
    {
      label: '$20',
      value: 20,
    },
    {
      label: '$50',
      value: 50,
    },
    {
      label: '$100',
      value: 100,
    },
    {
      label: '$500',
      value: 500,
    },
  ];

  variantsBySku: { [key: string]: ProductVariant };
  categoriesByEnrollment: any[] = [];
  allInterests: Interest[];
  isLoading = false;

  orders: OrderItem[] = [];
  donationAmountInputValue = 0;

  personTypes = PersonTypes;
  usingNewApiVersion: boolean = environment.shopifyApiVersion >= '2025-01';

  // shirtTypes = [
  //   { label: "Men's", value: ShirtType.Male },
  //   { label: "Women's", value: ShirtType.Female },
  // ];

  // shirtSizes = [
  //   { label: 'XS', value: ShirtSize.ExtraSmall },
  //   { label: 'S', value: ShirtSize.Small },
  //   { label: 'M', value: ShirtSize.Medium },
  //   { label: 'L', value: ShirtSize.Large },
  //   { label: 'XL', value: ShirtSize.ExtraLarge },
  //   { label: '2XL', value: ShirtSize.ExtraExtraLarge },
  // ];

  planSkus = this.shopifyService.planSkus;

  // plusUpgradePrice = this.variantsBySku[this.planSkus.racePlusUpgrade].priceV2.amount;
  podiumPackagePrice = this.shopifyService.podiumPackagePrice;

  get adminUrl(): string {
    return environment.adminUrl;
  }

  get internationalLicensePrice(): number {
    const prices = this.isAdult ? this.planSkus.adult : this.planSkus.junior;
    // TODO: @Brennan - Do we need to also handle race to intl+ (juniors only)?
    const price = this.isPlusPaid
      ? this.variantsBySku[prices.internationalPlusFromRacePlus].priceV2.amount
      : this.variantsBySku[prices.internationalFromRace].priceV2.amount;
    return parseInt(price, 10);
  }

  get plusUpgradePrice(): number {
    return parseInt(this.variantsBySku[this.planSkus.racePlusUpgrade].priceV2.amount, 10);
  }

  // get proLicensePrice(): number {
  //   return this.hasPlus ? this.planSkus.professionalPlus : this.planSkus.professional;
  // }

  get isPro(): boolean {
    return this.registration && this.registration.is_professional;
  }

  get showAutoRenew(): boolean {
    return (
      !(this.isCollegiate || this.isPro || this.registration.race_age === 18) &&
      (!this.podiumPackagesCount ||
        !!this.plusUpgradeCount ||
        this.internationalLicense ||
        this.chosenDonationAmount > 0)
    );
  }

  podiumAddonForm = new FormGroup({
    members: new FormArray([], atLeastOneSelected),
  });

  get podiumAddonFormMembers(): FormArray {
    return this.podiumAddonForm.get('members') as FormArray;
  }

  premiumAddonForm = new FormGroup({
    members: new FormArray([], atLeastOneSelected),
  });

  get premiumAddonFormMembers(): FormArray {
    return this.premiumAddonForm.get('members') as FormArray;
  }

  get podiumPackagesCount(): number {
    return this.order
      ? this.order.enrollments.filter(
          (enrollment) => enrollment.is_podium && !enrollment.is_podium_paid
        ).length
      : 0;
  }

  get plusUpgradeCount(): number {
    return this.order
      ? this.order.enrollments.filter(
          (enrollment) => enrollment.has_spot_insurance && !enrollment.is_spot_paid
        ).length
      : 0;
  }

  // get premiumUpgradesCount(): number {
  //   return this.order
  //     ? this.order.enrollments.filter(
  //         (enrollment) =>
  //           enrollment.is_premium &&
  //           !enrollment.is_premium_paid &&
  //           enrollment.person_type !== PersonTypes.JUNIOR &&
  //           !enrollment.is_collegiate_cyclist
  //       ).length
  //     : 0;
  // }

  // get proLicense(): boolean {
  //   return (
  //     this.order && this.mainEnrollment.is_professional && !this.mainEnrollment.is_professional_paid
  //   );
  // }

  get internationalLicense(): boolean {
    return (
      this.order &&
      this.mainEnrollment.is_international &&
      !this.mainEnrollment.is_international_paid
    );
  }

  get enrollmentsEligibleForPremium(): Registration[] {
    return this.order
      ? this.order.enrollments.filter(
          (enrollment) =>
            !enrollment.is_premium &&
            !enrollment.is_collegiate_cyclist &&
            enrollment.person_type === PersonTypes.ADULT
        )
      : [];
  }

  isTogglingPremium = false;

  get enrollmentsEligibleForPodium(): Registration[] {
    return this.order ? this.order.enrollments.filter((enrollment) => !enrollment.is_podium) : [];
  }

  // get missingPodiumSelection(): boolean {
  //   return this.order.enrollments.some(
  //     (enrollment) => enrollment.is_podium && !(enrollment.shirt_size && enrollment.shirt_type)
  //   );
  // }

  isTogglingPodium = false;

  get orderTotal(): number {
    let total = 0;

    if (this.plusUpgradeCount > 0) {
      total += this.plusUpgradeCount * this.plusUpgradePrice;
    }
    // if (this.proLicense) {
    //   total += this.proLicensePrice;
    // }
    if (this.internationalLicense) {
      total += this.internationalLicensePrice;
    }
    // if (this.premiumUpgradesCount > 0) {
    //   total += this.premiumUpgradesCount * this.premiumUpgradePrice;
    // }
    if (this.podiumPackagesCount > 0) {
      total += this.podiumPackagesCount * this.podiumPackagePrice;
    }
    if (this.chosenDonationAmount > 0) {
      total += this.chosenDonationAmount;
    }
    return total;
  }

  isSubmitting = false;
  serverError: any = null;
  autoRenew = false;

  constructor(
    private modal: NgbModal,
    private registrationService: RegistrationService,
    private interestsService: InterestsService,
    private registrationFacade: RegistrationFacade,
    private shopifyService: ShopifyService,
    private transactionService: TransactionService,
    private membershipOrdersService: MembershipOrdersService
  ) {}

  ngOnInit(): void {
    this.shopifyService.getProducts().then((products) => this.setVariantsBySku(products));
    this.loadInterests();
    this.loadOrder(this.registrationFacade.getOrderId);
  }

  private setVariantsBySku(products: Product[]): void {
    this.variantsBySku = products
      .flatMap((product) => product.variants)
      .reduce((acc, productVariant) => {
        acc[productVariant.sku] = productVariant;
        return acc;
      }, {});
  }

  private loadOrder(id: string): void {
    this.isLoading = true;
    this.membershipOrdersService
      .getById(id)
      .pipe(
        tap((order) => {
          this.order = order;
          this.categoriesByEnrollment = [];

          this.categoriesByEnrollment = [];
          order.enrollments.forEach((enrollment) => {
            const disciplines = this.allInterests
              .flatMap((x) => x.disciplines)
              .filter(
                (x) => x.collegiateCategories.length > 0 && x.nonCollegiateCategories.length > 0
              );
            const selectedCategories = enrollment.racing_categories || [];

            const categories: any[] = [];

            disciplines.forEach((discipline: Discipline) => {
              const selectedCategoryId = discipline.nonCollegiateCategories.findIndex((category) =>
                selectedCategories.some((rc) => rc.cd_type === category.id)
              );

              const selectedCategory = discipline.nonCollegiateCategories[selectedCategoryId];
              const isNovice = selectedCategoryId === 0;

              if (selectedCategory && !isNovice) {
                categories.push({
                  disciplineName: discipline.name,
                  categoryName: selectedCategory.name,
                  categoryRequiresPremium: selectedCategory.requiresPremium,
                });
              }
            });

            this.categoriesByEnrollment.push(categories);
          });

          // this.updatePremiumElligibleMembers(order);
          this.updatePodiumElligibleMembers(order);
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        }),
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe();
  }

  private loadInterests(): void {
    this.interestsService
      .getInterests()
      .pipe(
        tap((interests) => {
          this.allInterests = interests;
        })
      )
      .subscribe();
  }

  // updatePremiumElligibleMembers(order: MembershipOrder): void {
  //   this.premiumAddonFormMembers.clear();
  //   order.enrollments
  //     .filter(
  //       (enrollment) =>
  //         !enrollment.is_premium &&
  //         !enrollment.is_premium_paid &&
  //         !enrollment.is_collegiate_cyclist &&
  //         enrollment.person_type === PersonTypes.ADULT
  //     )
  //     .map(() => new FormControl(false))
  //     .forEach((control) => this.premiumAddonFormMembers.push(control));
  // }

  updatePodiumElligibleMembers(order: MembershipOrder): void {
    this.podiumAddonFormMembers.clear();
    order.enrollments
      .filter((enrollment) => !enrollment.is_podium && !enrollment.is_podium_paid)
      .map(() => new FormControl(false))
      .forEach((control) => this.podiumAddonFormMembers.push(control));
  }

  updateDonation(): void {
    this.chosenDonationAmount = this.donationAmountInputValue;
  }

  // addPremiumUpgrades(): void {
  //   const enrollments = this.premiumAddonFormMembers.value
  //     .map((selected: boolean, i: number) =>
  //       selected ? this.enrollmentsEligibleForPremium[i] : null
  //     )
  //     .filter((v?: Registration) => v !== null);

  //   this.togglePremium(enrollments, true);
  // }

  addPremium(enrollment: Registration): void {
    this.togglePremium([enrollment], true);
  }

  removePremium(enrollment: Registration): void {
    this.togglePremium([enrollment], false);
  }

  togglePremium(enrollments: Registration[], isPremium: boolean): void {
    const updateTasks = enrollments.map((enrollment) => {
      return this.registrationService.updateOptions(enrollment.id, {
        is_premium: isPremium,
      });
    });

    this.isTogglingPremium = true;
    forkJoin(updateTasks)
      .pipe(
        tap(() => {
          this.loadOrder(this.registrationFacade.getOrderId);
        }),
        finalize(() => {
          this.isTogglingPremium = false;
        })
      )
      .subscribe();
  }

  addPodiumPackages(): void {
    const enrollments = this.podiumAddonFormMembers.value
      .map((selected: boolean, i: number) =>
        selected ? this.enrollmentsEligibleForPodium[i] : null
      )
      .filter((v?: Registration) => v !== null);

    this.togglePodium(enrollments, true);
  }

  removePodiumPackage(enrollment: Registration): void {
    this.togglePodium([enrollment], false);
  }

  togglePodium(enrollments: Registration[], isPodium: boolean): void {
    const updateTasks = enrollments.map((enrollment) => {
      return this.registrationService.updateOptions(enrollment.id, {
        is_podium: isPodium,
      });
    });

    this.isTogglingPodium = true;
    forkJoin(updateTasks)
      .pipe(
        tap(() => {
          this.loadOrder(this.registrationFacade.getOrderId);
        }),
        finalize(() => {
          this.isTogglingPodium = false;
        })
      )
      .subscribe();
  }

  checkout(): void {
    this.serverError = null;
    this.isSubmitting = true;

    // Save options for all enrollments
    const requests = this.order.enrollments.map((enrollment) => {
      const options: {
        is_auto_renewal: boolean;
        is_premium: boolean;
        is_podium: boolean;
        has_spot_insurance: boolean;
      } = {
        is_auto_renewal: this.autoRenew,
        is_podium: enrollment.is_podium,
        is_premium: enrollment.is_premium,
        has_spot_insurance: enrollment.has_spot_insurance,
      };

      return this.registrationService.updateOptions(enrollment.id, options);
    });

    forkJoin(requests)
      .pipe(
        mergeMap(() =>
          from(
            this.shopifyService.getProducts().then((products) => {
              try {
                const variantsBySku: { [key: string]: ProductVariant } = products
                  .flatMap((product) => product.variants)
                  .reduce((acc, productVariant) => {
                    acc[productVariant.sku] = productVariant;
                    return acc;
                  }, {});

                // These are required for the custom thank you page redirect to work
                const customAttributes: Array<any> = [
                  {
                    key: 'source',
                    value: 'membership',
                  },
                  {
                    key: 'membership_order_id',
                    value: this.order.order_id,
                  },
                ];

                const note = `membership, post-purchase, ${MerchantAccountTag.Membership}`;

                const cart: { [sku: string]: number } = {};

                // tslint:disable-next-line: cyclomatic-complexity
                this.order.enrollments.forEach((enrollment) => {
                  let typeCode;
                  if (enrollment.is_collegiate_cyclist) {
                    typeCode = 'COL';
                  } else if (enrollment.person_type === PersonTypes.JUNIOR) {
                    typeCode = 'JUN';
                  }
                  const hasPlus = enrollment.has_spot_insurance;
                  const isPlusPaid = enrollment.is_spot_paid;

                  // Handle race plus upgrade
                  if (
                    hasPlus &&
                    !isPlusPaid &&
                    !enrollment.is_professional &&
                    !enrollment.is_international
                  ) {
                    let racePlusSku = 'RACE-PLUS';
                    if (typeCode) {
                      racePlusSku += `-${typeCode}-${typeCode}-UP`;
                    } else {
                      racePlusSku += '-RACE-UP';
                    }
                    cart[racePlusSku] = cart[racePlusSku] || 0;
                    cart[racePlusSku] += 1;
                  }

                  // Handle podium package (per member)
                  if (enrollment.is_podium && !enrollment.is_podium_paid) {
                    const podiumPackageSku = `PODIUM-MBR`;
                    cart[podiumPackageSku] = cart[podiumPackageSku] || 0;
                    cart[podiumPackageSku] += 1;
                  }

                  // Handle pro license upgrade
                  // if (
                  //   enrollment.is_professional &&
                  //   !enrollment.is_professional_paid &&
                  //   !this.checkLicense(LicenseFlag.PROFESSIONAL)
                  // ) {
                  //   const proLicenseSku = hasPlus ? 'PRO-PLUS' : 'PRO';
                  //   cart[proLicenseSku] = 1;
                  // }

                  // Handle international license upgrade
                  if (
                    this.mainEnrollment.is_international &&
                    !this.mainEnrollment.is_international_paid &&
                    !this.checkLicense(LicenseFlag.INTERNATIONAL)
                  ) {
                    let internationalLicenseSku = 'INT';
                    if (this.hasPlus) {
                      internationalLicenseSku += '-PLUS';
                    }
                    if (this.isJunior) {
                      internationalLicenseSku += '-JUN';
                    }
                    internationalLicenseSku += '-RACE';
                    if (this.isPlusPaid) {
                      internationalLicenseSku += '-PLUS';
                    }
                    internationalLicenseSku += '-UP';
                    if (this.isAdult) {
                      internationalLicenseSku += '-12-CRD';
                    }
                    cart[internationalLicenseSku] = 1;
                  }
                });

                // Handle donations (per order)
                if (this.chosenDonationAmount > 0) {
                  const donationSku = `MEM-DON-${this.chosenDonationAmount}`;
                  cart[donationSku] = 1;
                }

                const lineItems: Array<any> = Object.keys(cart).map((sku) => ({
                  variantId: variantsBySku[sku].id,
                  quantity: cart[sku],
                }));

                if (this.usingNewApiVersion) {
                  return this.shopifyService
                    .createCheckoutNew(lineItems, customAttributes, note)
                    .then((checkoutModel) => {
                      const transaction = {
                        shopify_uuid: checkoutModel.cart.id,
                        registrations: this.order.enrollments.map((enrollment) => ({
                          id: enrollment.id,
                        })),
                      };

                      return new Promise((resolve, reject) => {
                        this.transactionService.create(transaction).subscribe(
                          () => {
                            const defaultEnrollment = this.order.enrollments[0];

                            // Redirect for updated checkout flow (usac-checkout)
                            window.location.href = this.transactionService.getCartUrl(
                              checkoutModel.cart.id,
                              {
                                membershipOrder: this.order.order_id,
                                email: defaultEnrollment.email,
                                firstName: defaultEnrollment.first_name,
                                lastName: defaultEnrollment.last_name,
                                streetAddress: defaultEnrollment.address_street,
                                extendedAddress: defaultEnrollment.address_street2,
                                locality: defaultEnrollment.city,
                                region: defaultEnrollment.state,
                                postalCode: defaultEnrollment.zip,
                              }
                            );

                            // Original checkout web url (shopify checkout)
                            // window.location.href = checkoutModel.checkout.webUrl;
                            resolve(true);
                          },
                          () => {
                            reject();
                          }
                        );
                      });
                    });
                } else {
                  return this.shopifyService
                    .createCheckoutOld(lineItems, customAttributes, note)
                    .then((checkoutModel) => {
                      const transaction = {
                        shopify_uuid: checkoutModel.checkout.id,
                        registrations: this.order.enrollments.map((enrollment) => ({
                          id: enrollment.id,
                        })),
                      };

                      return new Promise((resolve, reject) => {
                        this.transactionService.create(transaction).subscribe(
                          () => {
                            const defaultEnrollment = this.order.enrollments[0];

                            // Redirect for updated checkout flow (usac-checkout)
                            window.location.href = this.transactionService.getCheckoutUrl(
                              // need to have the checkout model have cart and checkout
                              checkoutModel.checkout.id,
                              {
                                membershipOrder: this.order.order_id,
                                email: defaultEnrollment.email,
                                firstName: defaultEnrollment.first_name,
                                lastName: defaultEnrollment.last_name,
                                streetAddress: defaultEnrollment.address_street,
                                extendedAddress: defaultEnrollment.address_street2,
                                locality: defaultEnrollment.city,
                                region: defaultEnrollment.state,
                                postalCode: defaultEnrollment.zip,
                              }
                            );

                            // Original checkout web url (shopify checkout)
                            // window.location.href = checkoutModel.checkout.webUrl;
                            resolve(true);
                          },
                          () => {
                            reject();
                          }
                        );
                      });
                    });
                }
              } catch (error) {
                return Promise.reject(error);
              }
            })
          )
        )
      )
      .pipe(
        catchError((error) => {
          this.isSubmitting = false;
          this.serverError = error;
          return throwError(error);
        })
      )
      .subscribe();
  }

  openMemberInfoModal(enrollment: Registration): void {
    const modal = this.modal.open(MemberInformationModalComponent, {
      size: 'xl',
      centered: true,
    });

    // Set parameters
    modal.componentInstance.registrationId = enrollment.id;

    // Set callbacks
    modal.result.finally(() => {
      this.loadOrder(this.registrationFacade.getOrderId);
    });
  }

  openRaceDetails(enrollment: Registration): void {
    const modal = this.modal.open(RaceCategoriesModalComponent, {
      size: 'xl',
      centered: true,
    });

    // Set parameters
    modal.componentInstance.registrationId = enrollment.id;

    // Set callbacks
    modal.result.finally(() => {
      this.loadOrder(this.registrationFacade.getOrderId);
    });
  }

  private checkLicense(flag: LicenseFlag): boolean {
    return this.licenses.some((license) => !!license[flag]);
  }

  get mainEnrollment(): Registration {
    return this.order.enrollments[0];
  }

  get plan(): MembershipType {
    return this.mainEnrollment.membership_type;
  }

  get individualPlan(): boolean {
    return this.plan === MembershipType.Individual;
  }

  get isAdult(): boolean {
    return this.mainEnrollment && this.mainEnrollment.person_type === PersonTypes.ADULT;
  }

  get isJunior(): boolean {
    return this.mainEnrollment && this.mainEnrollment.person_type === PersonTypes.JUNIOR;
  }

  get paid(): boolean {
    return this.mainEnrollment.status === MembershipEnrollmentStatus.Paid;
  }

  get isCollegiate(): boolean {
    return this.mainEnrollment && this.mainEnrollment.is_collegiate_cyclist;
  }

  get isInternational(): boolean {
    return this.mainEnrollment && this.mainEnrollment.is_international;
  }

  get isCollegiateAdultInternational(): boolean {
    return this.isCollegiate && this.isAdult && this.isInternational;
  }

  get hasPlus(): boolean {
    return this.mainEnrollment.has_spot_insurance;
  }

  get isPlusPaid(): boolean {
    return this.mainEnrollment.is_spot_paid;
  }
}
