import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  License,
  Membership,
  MembershipStatus,
  MerchantAccountTag,
  PersonTypes,
  Product,
  ProductVariant,
  Profile,
} from 'src/app/core/models';
import {
  AuthService,
  LicenseService,
  ProfileService,
  ShopifyService,
  TransactionService,
} from 'src/app/core/services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ride-tech-upgrade',
  templateUrl: './ride-tech-upgrade.component.html',
  styleUrls: ['./ride-tech-upgrade.component.scss'],
})
export class RideTechUpgradeComponent implements OnInit {
  isSubmitting = false;
  licenses: License[];
  memberships: Membership[];
  rideTechSku = 'RIDE-TEC';
  serverError: any = null;
  upgradeForm: FormGroup;
  user: Profile;
  variantsBySku: { [key: string]: ProductVariant };
  usingNewApiVersion: boolean = environment.shopifyApiVersion >= '2025-01';

  get adminUrl(): string {
    return environment.adminUrl;
  }

  get loggedIn(): boolean {
    return !!this.user.profile_id;
  }

  get isLoading(): boolean {
    return !this.licenses || !this.memberships;
  }

  get personType(): PersonTypes {
    return this.loggedIn ? this.user.profile_person_type : null;
  }

  get isAdult(): boolean {
    return this.personType === PersonTypes.ADULT;
  }

  get isJunior(): boolean {
    return this.personType === PersonTypes.JUNIOR;
  }

  get rideTechPrice(): string {
    return this.variantsBySku[this.shopifyService.planSkus.rideTech].priceV2.amount;
  }

  get hasActiveMembership(): boolean {
    return (
      this.memberships &&
      this.memberships.some(
        (membership) => membership.membership_status === MembershipStatus.Active
      )
    );
  }

  get hasTechLicense(): boolean {
    return (
      this.licenses &&
      this.licenses.some(
        (license) =>
          moment(license.expiration_date) >= moment().startOf('day') &&
          this.licenseService.techLicenseTypeIds.includes(license.license_type)
      )
    );
  }

  get upgradeEligible(): boolean {
    return this.hasTechLicense && !this.hasActiveMembership;
  }

  get hideCartContent(): boolean {
    return !this.loggedIn || !this.upgradeEligible;
  }

  get ineligibilityText(): string {
    return this.hasActiveMembership
      ? 'Congratulations, you already have a Ride Membership!'
      : 'You are not currently a technical license holder, but you can purchase a standard Ride Membership.';
  }

  get ineligibilityBtnText(): string {
    return this.hasActiveMembership ? 'Go to My Account' : 'Get Ride Membership';
  }

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private licenseService: LicenseService,
    private profileService: ProfileService,
    private shopifyService: ShopifyService,
    private transactionService: TransactionService
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    this.authService.currentUser$.subscribe((user: Profile) => {
      this.user = user;
      if (this.loggedIn) {
        this.profileService.getLicenses(user.profile_id).subscribe((licenses) => {
          this.licenses = licenses;
        });
        this.profileService.getProfileMemberships(user.profile_id).subscribe((memberships) => {
          this.memberships = memberships;
        });
      }
    });

    this.shopifyService.getProducts().then((products) => this.setVariantsBySku(products));
  }

  private setVariantsBySku(products: Product[]): void {
    this.variantsBySku = products
      .flatMap((product) => product.variants)
      .reduce((acc, productVariant) => {
        acc[productVariant.sku] = productVariant;
        return acc;
      }, {});
  }

  private createForm(): void {
    this.upgradeForm = this.fb.group({
      is_auto_renewal: [false],
    });
  }

  login(): void {
    this.authService.doLogin(window.location.href);
  }

  checkout(): void {
    this.isSubmitting = true;

    this.shopifyService.getProducts().then((products) => {
      try {
        const variantsBySku: { [key: string]: ProductVariant } = products
          .flatMap((product) => product.variants)
          .reduce((acc, productVariant) => {
            acc[productVariant.sku] = productVariant;
            return acc;
          }, {});

        const customAttributes: any[] = [
          {
            key: 'source',
            value: 'membership',
          },
          {
            key: 'return_url',
            value: environment.accountUrl,
          },
        ];

        const lineItemCustomAttributes: any[] = [
          {
            key: 'recurring',
            value: this.upgradeForm.value.is_auto_renewal ? 'Yearly' : 'None',
          },
        ];

        const lineItems: Array<any> = [
          {
            variantId: variantsBySku[this.rideTechSku].id,
            quantity: 1,
            customAttributes: lineItemCustomAttributes,
          },
        ];

        const note = `membership, ride-tech-upgrade, ${MerchantAccountTag.Membership}`;

        if (this.usingNewApiVersion) {
          return this.shopifyService
            .createCheckoutNew(lineItems, customAttributes, note)
            .then((checkoutModel) => {
              const transaction = { shopify_uuid: checkoutModel.cart.id };

              return new Promise<void>((resolve, reject) => {
                this.transactionService
                  .createStandardTransaction(transaction)
                  .pipe(
                    catchError((err) => {
                      this.isSubmitting = false;
                      this.serverError = err;
                      return throwError(err);
                    })
                  )
                  .subscribe(
                    () => {
                      window.location.href = this.transactionService.getCartUrl(
                        checkoutModel.cart.id,
                        {
                          email: this.user.profile_email,
                          firstName: this.user.profile_first_name,
                          lastName: this.user.profile_last_name,
                        }
                      );
                      resolve();
                    },
                    () => {
                      reject();
                    }
                  );
              });
            });
        } else {
          return this.shopifyService
            .createCheckoutOld(lineItems, customAttributes, note)
            .then((checkoutModel) => {
              const transaction = { shopify_uuid: checkoutModel.checkout.id };

              return new Promise<void>((resolve, reject) => {
                this.transactionService
                  .createStandardTransaction(transaction)
                  .pipe(
                    catchError((err) => {
                      this.isSubmitting = false;
                      this.serverError = err;
                      return throwError(err);
                    })
                  )
                  .subscribe(
                    () => {
                      window.location.href = this.transactionService.getCheckoutUrl(
                        checkoutModel.checkout.id,
                        {
                          email: this.user.profile_email,
                          firstName: this.user.profile_first_name,
                          lastName: this.user.profile_last_name,
                        }
                      );
                      resolve();
                    },
                    () => {
                      reject();
                    }
                  );
              });
            });
        }
      } catch (error) {
        return Promise.reject(error);
      }
    });
  }

  ineligibilityBtnAction(): void {
    this.hasActiveMembership ? this.toAccount() : this.toRide();
  }

  toAccount(): void {
    window.location.assign(environment.accountUrl);
  }

  toRide(): void {
    window.location.assign(environment.shopifyStoreUrl + '/products/ride-membership');
  }
}
